import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ConstructionAlert from "./assets/ConstructionAlert";
import Header from "./assets/Header";
import { Grid, CircularProgress } from "@mui/material";
const root = ReactDOM.createRoot(document.getElementById("root"));

const Lineage = React.lazy(() => import("./pages/Lineage"));
const Sunder = React.lazy(() => import("./pages/Sunder"));
const Confini = React.lazy(() => import("./pages/Confini"));
const Yuuzu = React.lazy(() => import("./pages/Yuuzu"));

root.render(
	<React.StrictMode>
		<ConstructionAlert />
		<BrowserRouter>
			<Header />
			<React.Suspense
				fallback={
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						direction="column"
						style={{ minHeight: "100vh" }}
					>
						<Grid item>
							<CircularProgress />
						</Grid>
					</Grid>
				}
			>
				<Routes>
					<Route path="/" element={<Navigate to="/lineage" />} />
					<Route path={"/lineage"} element={<Lineage />} />
					<Route path={"/sunder"} element={<Sunder />} />
					<Route path={"/confini"} element={<Confini />} />
					<Route path={"/yuuzu"} element={<Yuuzu />} />
				</Routes>
			</React.Suspense>
		</BrowserRouter>
	</React.StrictMode>
);
