import * as React from "react";

import {
	AppBar,
	Tabs,
	Tab,
	Typography,
	Toolbar,
	Grid,
} from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "./darkTheme";
import { Link } from "react-router-dom";

const Header = () => {
	const [selectedTab, setSelectedTab] = React.useState(false);
	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	const handleClick = (event) => {
		setSelectedTab(false);
	};

	const routes = ["/lineage/", "/sunder/", "/confini/", "/yuuzu/"];
	const labels = ["Lineage", "Sunder", "Confini", "Yuuzu"];

	return (
		<div className="Header">
			<ThemeProvider theme={darkTheme}>
				<AppBar position="static">
					<Toolbar
						className="Toolbar"
						sx={{ display: "flex", justifyContent: "space-between" }}
						variant="dense"
					>
						<Typography
							sx={{ textDecoration: "none", color: "white" }}
							component={Link}
							to="/"
							onClick={handleClick}
						>
							twistie
						</Typography>
						<Tabs
							value={selectedTab}
							onChange={handleChange}
							variant="fullWidth"
						>
							{labels.map((label, i) => {
								return (
									<Tab
										className="Tab"
										label={label}
										value={i}
										component={Link}
										to={routes[i]}
									/>
								);
							})}
						</Tabs>
						<Grid></Grid>
					</Toolbar>
				</AppBar>
			</ThemeProvider>
		</div>
	);
};

export default Header;
