import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
	typography: {
		fontFamily: ["Montserrat"],
	},
});

export default darkTheme;
