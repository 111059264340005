import { Alert, Box, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const ConstructionAlert = () => {
	const [open, setOpen] = React.useState(true);
	return (
		<Box sx={{ width: "100%" }}>
			<Collapse in={open}>
				<Alert
					severity="info"
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setOpen(false);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					This site is still under construction and the performance may not be
					great. Please be patient while I work on it. -Twistie
				</Alert>
			</Collapse>
		</Box>
	);
};

export default ConstructionAlert;
